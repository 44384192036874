<template>
  <div>
    <edit
      :is-system-admin="true"
      redirect-route="admin-announcements-list"
      resource="global-ActivityController"
    />
  </div>
</template>
<script>
import edit from '../edit.vue'

export default {
  components: { edit },
}
</script>
<style lang="">

</style>
