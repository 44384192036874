<template>
  <b-card header="Edit Announcement">
    <spinner-loader
      :loading="loading"
    />

    <announcement-form
      v-if="!loading"
      :announcement="announcement"
      :submit="editAnnouncement"
      :progress="progress"
      :is-system-admin="isSystemAdmin"
    />
  </b-card>
</template>

<script>
import AnnouncementForm from '@/common/components/Announcements/AnnouncementForm.vue'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import handleFormData from '@/common/compositions/common/handleFormData'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import announcementsApi from '@/common/compositions/Announcements/announcementsApi'

export default {
  name: 'EditAnnouncement',
  components: { AnnouncementForm, SpinnerLoader },
  props: {
    isSystemAdmin: { type: Boolean, default: false },
    redirectRoute: { type: String, default: 'announcements-list' },
    resource: { type: String, default: 'ActivityController' },
  },
  computed: {
    formattedAnnouncement() {
      return {
        ...this.announcement,
        youtubeCover: this.announcement.youtubeCover[0],
        cta_icon: this.announcement.cta_icon[0],
        startsAt: this.$moment(this.announcement.startsAt).format('YYYY-MM-DD HH:mm'),
        endsAt: this.$moment(this.announcement.endsAt).format('YYYY-MM-DD HH:mm'),
        cta_value: this.announcement.cta_type === 'Donation to specific Organization'
          ? `${this.announcement.organization.id},${this.announcement.donationCategory.id}`
          : this.announcement.cta_value,
      }
    },
  },
  provide() {
    return {
      resource: this.resource,
    }
  },
  setup() {
    const { progress, calculateUploadPercentage } = uploadPercentage()
    const { formData, setFormData } = handleFormData()
    const {
      getAnnouncement, announcement, loading, showPopup,
    } = announcementsApi()
    return {
      formData, setFormData, progress, calculateUploadPercentage, getAnnouncement, announcement, loading, showPopup,
    }
  },
  created() {
    this.getAnnouncement()
  },
  methods: {
    editAnnouncement() {
      this.setFormData(this.formattedAnnouncement)
      return this.$activities.post(`/internalops/announcement/${this.$route.params.id}?_method=PUT`, this.formData,
        {
          onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
        }).then(() => {
        this.showPopup(this.formattedAnnouncement.status).then(() => {
          this.$router.push({ name: this.redirectRoute })
        })
      })
    },
  },
}
</script>

<style lang="scss">
</style>
